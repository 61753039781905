import { combineReducers } from 'redux';
import { RootAction } from '@src/app/_redux';
import { COGNITO_SUCCESS } from '@src/app/login/cognito/actions';

export type State = {
  user?: any;
  readonly loading: boolean;
};

export const reducer = combineReducers<State, RootAction>({
  loading: (state = false, action) => {
    switch (action.type) {
      default:
        return state;
    }
  },
  user: (state = null, action) => {
    switch (action.type) {
      case COGNITO_SUCCESS:
        return { ...action.payload,
                 email: getEmailFromIdToken(action.payload.idToken),
                 channel: 'cognito'
               };
      default:
        return state;
    }
  },
});

const getEmailFromIdToken = (token: {jwtToken: string}): string => {
  const idToken = token.jwtToken;

  let tokenParts = idToken.split('.');
  let tokenPayload = tokenParts[1];
  const decodedIdToken = atob(tokenPayload);
  const parsedToken = JSON.parse(decodedIdToken);
  if (parsedToken.emails && parsedToken.emails.length > 0) {
    return parsedToken.emails[0];
  }
  return parsedToken.email;
};
