import { combineReducers } from 'redux';
import { RootAction } from '@src/app/_redux';
import {
  RENEWALS_LOADING,
  RENEWALS_ERROR,
  RENEWALS_START,
  RENEWALS_SUCCESS,
  RENEWALS_CANCEL,
  PROMOCODEINQ_LOADING,
  PROMOCODEINQ_ERROR,
  PROMOCODEINQ_START,
  PROMOCODEINQ_SUCCESS,
  PROMOCODEINQ_CANCEL,
} from '@src/app/accounts/renewals/actions';
import { OMS_LOOKUP_SUCCESS } from '@src/app/login/oms/actions';
export type State = {
  readonly renewalLoading: boolean;
  readonly promoCodeInqLoading: boolean;
  renewalsSavedResult: Object | null;
  promoCodeInqResult: Object | null;
};

export const reducer = combineReducers<State, RootAction>({
  renewalLoading: (state = false, action) => {
    switch (action.type) {
      case RENEWALS_ERROR:
      case RENEWALS_START:
      case OMS_LOOKUP_SUCCESS:
        return false;
      case RENEWALS_LOADING:
        return action.payload;
      default:
        return state;
    }
  },

  renewalsSavedResult: (state = null, action) => {
    switch (action.type) {
      case RENEWALS_SUCCESS:
      case RENEWALS_ERROR:
        return action.payload;
      case RENEWALS_CANCEL:
      case RENEWALS_START:
        return null;
      default:
        return state;
    }
  },
  promoCodeInqLoading: (state = false, action) => {
    switch (action.type) {
      case PROMOCODEINQ_ERROR:
      case PROMOCODEINQ_SUCCESS:
        return false;
      case PROMOCODEINQ_LOADING:
        return action.payload;
      default:
        return state;
    }
  },

  promoCodeInqResult: (state = null, action) => {
    switch (action.type) {
      case PROMOCODEINQ_SUCCESS:
      case PROMOCODEINQ_ERROR:
        return action.payload;
      case PROMOCODEINQ_CANCEL:
      case PROMOCODEINQ_START:
        return null;
      default:
        return state;
    }
  },
});
