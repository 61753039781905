import { combineEpics, Epic, ActionsObservable, StateObservable, ofType } from 'redux-observable';
import { RootAction, RootState } from '@src/app/_redux';
import { ApiClient } from '@src/app/_global/utility/apiUtils';
import { ApiConfig } from '@src/app/_global/domain';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { actionCreators, RESUME_START } from '@src/app/accounts/resume/actions';
import { actionCreators as weakActionCreators } from '@src/app/login/weak/actions';
import { actionCreators as gaActionCreators } from '@src/app/analytics/googleAnalytics/actions';
import { getLoggedInCustomer } from '@src/app/accounts/selectors';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { ResumeWSGPayload } from '@src/_api/payload/resume';

const SERV_RESUME_PATH = '/cofp';
const PF_RESUME_PATH = '/series';
const getResumesSucceeded = (...responses) => {
  const successResponses = responses.filter(item => 
    item.success === true);
  return successResponses;
};

  const resumeSubs =  (
    action$: ActionsObservable<RootAction>,
    state$: StateObservable<RootState>,
  ) =>
      action$.pipe(
        ofType(RESUME_START),
        mergeMap(({ payload }) => {
        const client = new ApiClient();
        const clientConfig = state$.value.clientConfig.clientConfig;
        const loading = of(actionCreators.resumeLoading(true));

        const promises = payload.map(resume => {
          let postBody: ResumeWSGPayload = {
            prodIdAlias: '',
            accountNumber: '',
          };
          let path = '';
          let requestMethod = '';
          if (resume.systemCode === 'SERV') {
            postBody = {
              prodIdAlias: resume.prodIdAlias,
              accountNumber: resume.accountNumber,
              resume: 'Y',
              currentMagSwitch: 'X'
            };
            path = SERV_RESUME_PATH;
            requestMethod = 'POST';
          } else if (resume.systemCode === 'SERVPF') {
            postBody = {
              prodIdAlias: resume.prodIdAlias,
              accountNumber: resume.accountNumber,
              suspendSwitch: 'N',
              recipAccountNumber: resume.recipAccountNumber,
              updateType: 'suspendresume',
              planCode: resume.planCode,
              shipFrequency: resume.shipFrequency,
            };
            path = PF_RESUME_PATH;
            requestMethod = 'PUT';
          }
          const apiConfig: ApiConfig = {
            credentialsObject: state$.value.auth.omsAuth.credentials,
            providerType: clientConfig.providerType,
            url: clientConfig.url,
            path: path,
            region: clientConfig.Region,
            requestmethod: requestMethod,
            requestbody: postBody,
            parms: {},
            additionalParms: {
              headers: {
                'x-session-id': clientConfig.uuid
              },
            },
          };
          return fromPromise(client.callApiGateway(apiConfig));
        });
        let loggedIncustomer = getLoggedInCustomer(state$.value);
        const refetchCustomerPayload = {
          accountNumber: loggedIncustomer.accountNumber,
          postalCode: loggedIncustomer.postalCode,
        };
        let reloadCust = of(weakActionCreators.refetchCustomer(refetchCustomerPayload));
        const source$ = forkJoin(promises, getResumesSucceeded).pipe(
          map(responses => {
            let resumeCount = payload.length;
            if (resumeCount === responses.length) {
              payload.forEach(resumePayload => {
                gaActionCreators.createGAEvent({
                  category: resumePayload.googleAnalyticsCategory,
                  action: 'Successful',
                  label: resumePayload.prodIdAlias,
                });
              });

              return actionCreators.resumeSuccess({payload, responses, success: true});
            } else {
              payload.forEach(resumePayload => {
                gaActionCreators.createGAEvent({
                  category: resumePayload.googleAnalyticsCategory,
                  action: 'Unsuccessful',
                  label: resumePayload.prodIdAlias,
                });
              });
              return actionCreators.resumeError({
                success: false,
                message: 'Error on Resume',
              });
            }
          }),
          catchError(err => {
            gaActionCreators.createGAEvent({
              category: payload[0].googleAnalyticsCategory,
              action: 'Unknown Error',
              label: payload[0].prodIdAlias,
            });
            return of(
              actionCreators.resumeError({ success: false, message: err }),
            );
          }),
        );
        return concat(loading, source$, reloadCust);
      }),
    );
export const epics = combineEpics(resumeSubs);
