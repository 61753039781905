import {
  ActionsObservable,
  StateObservable,
  ofType,
  combineEpics,
} from 'redux-observable';
import { RootAction, RootState } from '@src/app/_redux';
import { EditEmailPayload } from '@src/_api/payload';
import { ApiClient } from '@src/app/_global/utility/apiUtils';
import { ApiConfig } from '@src/app/_global/domain';
import { UpdateEmailResponse } from '@src/app/_global/domain';
import { actionCreators as gaActionCreators } from '@src/app/analytics/googleAnalytics/actions';
import { actionCreators as weakActionCreators } from '@src/app/login/weak/actions';
import {
  EMAIL_SAVE_START,
  ACCOUNT_SAVE_START,
  AUTO_COFE_SAVE_START,
  RESET_PASSWORD_START,
  actionCreators,
} from '@src/app/accounts/summary';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { concat } from 'rxjs/observable/concat';
import { delay } from 'rxjs/operators';
import { EditAccountPayload } from '@src/_api/payload/edit-account';
import { UpdateAccountResponse } from '@src/app/_global/domain/UpdateAccountResponse';
import { getLoggedInCustomer } from '@src/app/accounts/selectors';

const saveAccountEpic = (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    ofType(ACCOUNT_SAVE_START),
    mergeMap(({ payload }) => {
      const clientConfig = state$.value.clientConfig.clientConfig;
      const loading = of(actionCreators.accountSaveLoading(true));
      const client = new ApiClient();

      const accountNumbers = payload.payload.map(item => item.accountNumber);
      const newPostalCode = payload.payload[0].postalCode;

      const promises = payload.payload.map(item => {
        let postBody: EditAccountPayload = item;
        const apiConfig: ApiConfig = {
          credentialsObject: state$.value.auth.omsAuth.credentials,
          providerType: clientConfig.providerType,
          url: clientConfig.url,
          path: '/cofa',
          requestmethod: 'POST',
          region: clientConfig.Region,
          requestbody: postBody,
          parms: {},
          additionalParms: {
            headers: {
              'x-session-id': clientConfig.uuid
            },
          },
        };
        return fromPromise(client.callApiGateway(apiConfig));
      });

      const source$ = forkJoin(promises, getAllSucceeded).pipe(
        map(allSucceeded => {
          if (allSucceeded) {
            payload.payload.forEach(cofaPayload => {
              gaActionCreators.createGAEvent({
                category: cofaPayload.googleAnalyticsCategory,
                action: 'Successful',
                label: cofaPayload.prodIdAlias,
              });
            });

            let accountResponse: UpdateAccountResponse;
            if (payload.firstResponse) {
              accountResponse = payload.firstResponse;
            } else {
              accountResponse = {
                success: allSucceeded,
                accountNumbers,
              };
            }
            if (payload.secondPayload && payload.secondPayload.length > 0) {
              return actionCreators.accountSaveStart({
                payload: payload.secondPayload,
                firstResponse: accountResponse,
              });
            } else {
              return actionCreators.accountSaveSuccess({accountResponse: payload.payload, success: allSucceeded});
            }
          } else {
            payload.payload.forEach(cofaPayload => {
              gaActionCreators.createGAEvent({
                category: cofaPayload.googleAnalyticsCategory,
                action: 'Unsuccessful',
                label: cofaPayload.prodIdAlias,
              });
            });
            return actionCreators.accountSaveError({
              success: false,
              message: 'Error on Change of Address',
            });
          }
        }),
        catchError(err => {
          gaActionCreators.createGAEvent({
            category: payload.payload[0].googleAnalyticsCategory,
            action: 'Unknown Error',
            label: payload.payload[0].prodIdAlias,
          });
          return of(
            actionCreators.accountSaveError({ success: false, message: err }),
          );
        }),
      );

      if (payload.secondPayload && payload.secondPayload.length > 0) {
        return concat(loading, source$);
      } else {
        let loggedIncustomer = getLoggedInCustomer(state$.value);
        const refetchCustomerPayload = {
          accountNumber: loggedIncustomer.accountNumber,
          postalCode: newPostalCode,
        };
        let reloadCust = of(weakActionCreators.refetchCustomer(refetchCustomerPayload));
        // let reloadCust = of(setTimeout(weakActionCreators.refetchCustomer(refetchCustomerPayload), 2000));
        return concat(loading, source$, reloadCust.pipe(delay(2000)));
      }
    }),
  );

const getAllSucceeded = (...responses) => {
  const successCount = responses.filter(item => item.success);
  return successCount.length === responses.length;
};

const saveEmailEpic = (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    ofType(EMAIL_SAVE_START),
    mergeMap(({ payload }) => {
      const clientConfig = state$.value.clientConfig.clientConfig;
      const loading = of(actionCreators.accountSaveLoading(true));
      const client = new ApiClient();

      const accountNumbers = payload.map(item => item.accountNumber);
      const email = payload[0].email;

      const promises = payload.map(item => {
        let postBody: EditEmailPayload = item;
        const apiConfig: any = {
          credentialsObject: state$.value.auth.omsAuth.credentials,
          providerType: clientConfig.providerType,
          url: clientConfig.url,
          path: '/cofe',
          requestmethod: 'POST',
          region: clientConfig.Region,
          requestbody: postBody,
          parms: {},
          additionalParms: {
            headers: {
              'x-session-id': clientConfig.uuid
            },
          },
        };
        return fromPromise(client.callApiGateway(apiConfig));
      });

      const source$ = forkJoin(promises, getAllSucceeded).pipe(
        map(allSucceeded => {
          if (allSucceeded) {
            payload.forEach(cofePayload => {
              gaActionCreators.createGAEvent({
                category: cofePayload.googleAnalyticsCategory,
                action: 'Successful',
                label: cofePayload.prodIdAlias,
              });
            });
            let emailResponse: UpdateEmailResponse = {
              success: allSucceeded,
              email,
              accountNumbers,
            };
            return actionCreators.emailSaveSuccess(emailResponse);
          } else {
            payload.forEach(cofePayload => {
              gaActionCreators.createGAEvent({
                category: cofePayload.googleAnalyticsCategory,
                action: 'Unsuccessful',
                label: cofePayload.prodIdAlias,
              });
            });
            return actionCreators.emailSaveError({
              success: false,
              message: 'Error on Change of Email',
            });
          }
        }),
        catchError(err => {
          gaActionCreators.createGAEvent({
            category: payload[0].googleAnalyticsCategory,
            action: 'Unknown Error',
            label: payload[0].prodIdAlias,
          });
          return of(
            actionCreators.emailSaveError({ success: false, message: err }),
          );
        }),
      );
      let loggedIncustomer = getLoggedInCustomer(state$.value);
      const refetchCustomerPayload = {
        accountNumber: loggedIncustomer.accountNumber,
        postalCode: loggedIncustomer.postalCode,
      };
      let reloadCust = of(weakActionCreators.refetchCustomer(refetchCustomerPayload));
      return concat(loading, source$, reloadCust.pipe(delay(2000)));
    }),
  );
  const saveAutoCofeEpic = (
    action$: ActionsObservable<RootAction>,
    state$: StateObservable<RootState>,
  ) =>
    action$.pipe(
      ofType(AUTO_COFE_SAVE_START),
      mergeMap(({ payload }) => {
        const clientConfig = state$.value.clientConfig.clientConfig;
        const client = new ApiClient();

        let customers = state$.value.account.customerData.customers;
        if (!customers) {
          console.log('AutoCofe epic no customers');
          return of(actionCreators.autoCofeSaveError({
            success: false,
            message: 'No Auto COFE From Bad Account Lookup',
          }));
        }
        const email = payload.email;
        let accountNumbers = Array();
        const promises = customers.map(item => {
          let postBody: EditEmailPayload = {            
            email: '',
            prodIdAlias: '',
            accountNumber: ''};
          if (item.systemCode === 'SERV' || item.systemCode === 'EHUB') {
            postBody = {
              email: email,
              prodIdAlias: item.prodIdAlias,
              accountNumber: item.accountNumber,
              emailAuthorization: payload.emailOptIn,
            };
          } else if (item.systemCode === 'SERVPF') {
            postBody = {
              email: email,
              prodIdAlias: item.prodIdAlias,
              accountNumber: item.accountNumber,
              emailPermission1: payload.emailOptIn,
            };
          }
          accountNumbers.push(item.accountNumber);
          const apiConfig: any = {
            credentialsObject: state$.value.auth.omsAuth.credentials,
            providerType: clientConfig.providerType,
            url: clientConfig.url,
            path: '/cofe',
            requestmethod: 'POST',
            region: clientConfig.Region,
            requestbody: postBody,
            parms: {},
            additionalParms: {
              headers: {
                'x-session-id': clientConfig.uuid
              },
            },
          };
          return fromPromise(client.callApiGateway(apiConfig));
        });
  
        const source$ = forkJoin(promises, getAllSucceeded).pipe(
          map(allSucceeded => {
            if (allSucceeded) {
              let emailResponse: UpdateEmailResponse = {
                success: allSucceeded,
                email,
                accountNumbers,
              };
              gaActionCreators.createGAEvent({
                category: payload.googleAnalyticsCategory,
                action: 'Successful',
                label: payload.prodIdAlias
              });
              return actionCreators.autoCofeSaveSuccess(emailResponse);
            } else {
              gaActionCreators.createGAEvent({
                category: payload.googleAnalyticsCategory,
                action: 'Unsuccessful',
                label: payload.prodIdAlias
              });
              return actionCreators.autoCofeSaveError({
                success: false,
                message: 'Error on Change of Email',
              });
            }
          }),
          catchError(err => {
            gaActionCreators.createGAEvent({
              category: payload.googleAnalyticsCategory,
              action: 'Unknown Error',
              label: payload.prodIdAlias,
            });
            return of(
              actionCreators.emailSaveError({ success: false, message: err }),
            );
          }),
        );
        let loggedIncustomer = getLoggedInCustomer(state$.value);
        const refetchCustomerPayload = {
          accountNumber: loggedIncustomer.accountNumber,
          postalCode: loggedIncustomer.address.postalCode,
        };
        let reloadCust = of(weakActionCreators.refetchCustomer(refetchCustomerPayload));
        return concat( source$, reloadCust.pipe(delay(3000)));
      }),
    );

    const resetPasswordEpic = (
      action$: ActionsObservable<RootAction>,
      state$: StateObservable<RootState>,
    ) =>
      action$.pipe(
        ofType(RESET_PASSWORD_START),
        mergeMap(({ payload }) => {
          const clientConfig = state$.value.clientConfig.clientConfig;
          const loading = of(actionCreators.accountSaveLoading(true));
          const client = new ApiClient();
    
          let postBody: any = {
            email: payload.email,
            clientId: payload.clientId,
          };
    
          const apiConfig: any = {
            credentialsObject: state$.value.auth.omsAuth.credentials,
            providerType: clientConfig.providerType,
            url: clientConfig.url,
            path: '/passwordreset',
            requestmethod: 'POST',
            region: clientConfig.Region,
            requestbody: postBody,
            parms: {},
            additionalParms: {
              headers: {
                'x-session-id': clientConfig.uuid
              },
            },
          };

          const source$ = fromPromise(client.callApiGateway(apiConfig)).pipe(
            map(answer => {
              if (answer.success) {
                gaActionCreators.createGAEvent({
                  category: payload.googleAnalyticsCategory,
                  action: 'Successful',
                  label: payload.prodIdAlias,
                });
                // add the history to the response to redirect in action
                answer.history = payload.history;
                return actionCreators.resetPasswordSuccess(answer);
              } else {
                gaActionCreators.createGAEvent({
                  category: payload.googleAnalyticsCategory,
                  action: 'Unsuccessful',
                  label: payload.prodIdAlias,
                });
                return actionCreators.resetPasswordError({
                  success: false,
                  message: 'Error Sending Password Reset',
                });
              }
            }),
            catchError(err => {
              gaActionCreators.createGAEvent({
                category: payload.googleAnalyticsCategory,
                action: 'Unknown Error',
                label: payload.prodIdAlias,
              });
              return of(
                actionCreators.resetPasswordError({ success: false, message: err }),
              );
            }),
          );
          return concat(loading, source$);
        }),
      );
    
export const epics = combineEpics(saveAccountEpic, saveEmailEpic, saveAutoCofeEpic, resetPasswordEpic);