import Mylo from 'mylo-js';
import { CognitoAuthData } from '../components/Cognito';

export const COGNITO_GET_IDENTITY_ID = 'COGNITO_GET_IDENTITY_ID';
export const COGNITO_SUCCESS = 'COGNITO_SUCCESS';
export const COGNITO_REFRESH = 'COGNITO_REFRESH';
export const COGNITO_REFRESH_SUCCESS = 'COGNITO_REFRESH_SUCCESS';

export type Actions = {
    COGNITO_GET_IDENTITY_ID: { type: typeof COGNITO_GET_IDENTITY_ID; payload: any };
    COGNITO_SUCCESS: { type: typeof COGNITO_SUCCESS; payload: any };
    COGNITO_REFRESH: { type: typeof COGNITO_REFRESH; payload: any };
    COGNITO_REFRESH_SUCCESS: { type: typeof COGNITO_REFRESH_SUCCESS; payload: any };
};

export const actionCreators = {
    cognitoGetIdentityId: (payload: any): Actions[typeof COGNITO_GET_IDENTITY_ID] => ({
        type: COGNITO_GET_IDENTITY_ID,
        payload,
    }),
    cognitoSuccess: (payload: any): Actions[typeof COGNITO_SUCCESS] => ({
        type: COGNITO_SUCCESS,
        payload,
    }),
    cognitoRefresh: (payload: any): Actions[typeof COGNITO_REFRESH] => ({
        type: COGNITO_REFRESH,
        payload,
    }),
    cognitoRefreshSuccess: (payload: any): Actions[typeof COGNITO_REFRESH_SUCCESS] => {
        payload.history.push('/accounts/summary');
        return {
            type: COGNITO_REFRESH_SUCCESS,
            payload,
        };
    },
};

export const cognitoLogout = (authLogin: boolean, myloConfig: any, providerType: string, authData: CognitoAuthData) => {
    if (authLogin) {
        if (providerType === 'CoreSSO') {
            window.location.href = `${authData.AppWebDomain}/logout?post_logout_redirect_uri=${authData.RedirectUriSignOut}`;
        } else {
            let mylo = new Mylo(myloConfig);
            mylo.viewPopup('logout');
        }
    } else {
        window.location.href = '/logout';
    }
};
