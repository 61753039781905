import { combineEpics, Epic, ActionsObservable, StateObservable, ofType } from 'redux-observable';
import { RootAction, RootState } from '@src/app/_redux';
import { ApiClient } from '@src/app/_global/utility/apiUtils';
import { ApiConfig } from '@src/app/_global/domain';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { actionCreators, UPDATE_PAYMENT_START } from '@src/app/accounts/updatepayment/actions';
import { actionCreators as weakActionCreators } from '@src/app/login/weak/actions';
import { actionCreators as gaActionCreators } from '@src/app/analytics/googleAnalytics/actions';
import { getLoggedInCustomer } from '@src/app/accounts/selectors';
import { PFUpdateSeriesPostbody, UpdateCardPostbody } from '@src/_api/payload/update-payment';
import { forkJoin } from 'rxjs/observable/forkJoin';

const PF_SERIES_UPDATE_PATH = '/series';
const CARD_UPDATE_PATH = '/cardupdate';

const getSeriesUpdateSucceeded = (...responses) => {
  const successResponses = responses.filter(item => 
    item.success === true);
  return successResponses;
};

const updatePayment =  (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<RootState>,
) =>
    action$.pipe(
      ofType(UPDATE_PAYMENT_START),
      mergeMap(({ payload }) => {
      const client = new ApiClient();
      const clientConfig = state$.value.clientConfig.clientConfig;
      const loading = of(actionCreators.updatePaymentLoading(true));

      const promises = payload.map(updatePaymentPayload => {
        let wsgBody: PFUpdateSeriesPostbody | UpdateCardPostbody = {
          prodIdAlias: '',
          accountNumber: '',
          recipAccountNumber: '',
          planCode: '',
          updateType: '',
          orderType: '',
          creditCardExpireMonth: '',
          creditCardExpireYear: '',
          creditCardNumber: '',
          creditCardLastFour: '',
        };
        let path = '';
        if (updatePaymentPayload.systemCode === 'SERV') {
          wsgBody = {
            prodIdAlias: updatePaymentPayload.prodIdAlias,
            accountNumber: updatePaymentPayload.accountNumber,
            name: updatePaymentPayload.name,
            creditCardType: updatePaymentPayload.creditCardType,
            creditCardExpireMonth:  updatePaymentPayload.creditCardExpireMonth,
            creditCardExpireYear:  updatePaymentPayload.creditCardExpireYear,
            creditCardNumber:  updatePaymentPayload.creditCardNumber,
            oldCreditCardType: updatePaymentPayload.oldCreditCardType,
            oldCreditCardExpireMonth:  updatePaymentPayload.oldCreditCardExpireMonth,
            oldCreditCardExpireYear:  updatePaymentPayload.oldCreditCardExpireYear,
            oldCreditCardNumber:  updatePaymentPayload.oldCreditCardNumber,
            transactionNumber: updatePaymentPayload.transactionNumber,
            creditCardLastFour: updatePaymentPayload.creditCardLastFour,
          };
          path = CARD_UPDATE_PATH;
        } else if (updatePaymentPayload.systemCode === 'EHUB') {
          wsgBody = {
            prodIdAlias: updatePaymentPayload.prodIdAlias,
            accountNumber: updatePaymentPayload.accountNumber,
            name: updatePaymentPayload.name,
            creditCardType: updatePaymentPayload.creditCardType,
            creditCardExpireMonth:  updatePaymentPayload.creditCardExpireMonth,
            creditCardExpireYear:  updatePaymentPayload.creditCardExpireYear,
            creditCardNumber:  updatePaymentPayload.creditCardNumber,
            cardProfileId: updatePaymentPayload.cardProfileId,
            creditCardLastFour: updatePaymentPayload.creditCardLastFour,
          };
          path = CARD_UPDATE_PATH;
        } else if (updatePaymentPayload.systemCode === 'SERVPF') {
          wsgBody = {
            prodIdAlias: updatePaymentPayload.prodIdAlias,
            accountNumber: updatePaymentPayload.accountNumber,
            recipAccountNumber:  updatePaymentPayload.recipAccountNumber,
            planCode:  updatePaymentPayload.planCode,
            updateType:  updatePaymentPayload.updateType,
            orderType:  updatePaymentPayload.orderType,
            creditCardExpireMonth:  updatePaymentPayload.creditCardExpireMonth,
            creditCardExpireYear:  updatePaymentPayload.creditCardExpireYear,
            creditCardNumber:  updatePaymentPayload.creditCardNumber,
            creditCardType: updatePaymentPayload.creditCardType,
            shipFrequency: updatePaymentPayload.shipFrequency,
            creditCardLastFour: updatePaymentPayload.creditCardLastFour,
          };
          path = PF_SERIES_UPDATE_PATH;
        }
        const apiConfig: ApiConfig = {
          credentialsObject: state$.value.auth.omsAuth.credentials,
          providerType: clientConfig.providerType,
          url: clientConfig.url,
          path: path,
          region: clientConfig.Region,
          requestmethod: 'PUT',
          requestbody: wsgBody,
          parms: {},
          additionalParms: {
            headers: {
              'x-session-id': clientConfig.uuid
            },
          },
        };
        return fromPromise(client.callApiGateway(apiConfig));
      });
      let loggedIncustomer = getLoggedInCustomer(state$.value);
      const refetchCustomerPayload = {
        accountNumber: loggedIncustomer.accountNumber,
        postalCode: loggedIncustomer.postalCode,
      };
      let reloadCust = of(weakActionCreators.refetchCustomer(refetchCustomerPayload));
      const source$ = forkJoin(promises, getSeriesUpdateSucceeded).pipe(
        map(responses => {
          let updateCount = payload.length;
          if (updateCount === responses.length) {
            payload.forEach(updatePmtPayload => {
              gaActionCreators.createGAEvent({
                category: updatePmtPayload.googleAnalyticsCategory,
                action: 'Successful',
                label: updatePmtPayload.prodIdAlias,
              });
            });
            return actionCreators.updatePaymentSuccess({payload, responses, success: true});
          } else {
            payload.forEach(updatePmtPayload => {
              gaActionCreators.createGAEvent({
                category: updatePmtPayload.googleAnalyticsCategory,
                action: 'Unsuccessful',
                label: updatePmtPayload.prodIdAlias,
              });
            });
            return actionCreators.updatePaymentError({
              success: false,
              message: 'Error on ' + payload[0].googleAnalyticsEventCategory,
            });
          }
        }),
        catchError(err => {
          gaActionCreators.createGAEvent({
            category: payload[0].googleAnalyticsEventCategory,
            action: 'Unknown Error',
            label: payload[0].prodIdAlias,
          });
          return of(
            actionCreators.updatePaymentError({ success: false, message: err }),
          );
        }),
      );
      return concat(loading, source$, reloadCust);
    }),
  );
export const epics = combineEpics(updatePayment);
