import { combineEpics, Epic, ActionsObservable, StateObservable, ofType } from 'redux-observable';
import { RootAction, RootState } from '@src/app/_redux';
import { ApiClient } from '@src/app/_global/utility/apiUtils';
import { ApiConfig } from '@src/app/_global/domain';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { actionCreators, STOP_AUTO_RENEW_START } from '@src/app/accounts/stopautorenew/actions';
import { actionCreators as weakActionCreators } from '@src/app/login/weak/actions';
import { actionCreators as gaActionCreators } from '@src/app/analytics/googleAnalytics/actions';
import { getLoggedInCustomer } from '@src/app/accounts/selectors';
import { StopAutoRenewBody } from '@src/_api/payload/stopauto-renew';
import { forkJoin } from 'rxjs/observable/forkJoin';

const STOP_AUTO_RENEW_PATH = '/cofp';
const getStopAutoRenewSucceeded = (...responses) => {
  const successResponses = responses.filter(item => 
    item.success === true);
  return successResponses;
};
const stopAutoRenew =  (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<RootState>,
) =>
    action$.pipe(
      ofType(STOP_AUTO_RENEW_START),
      mergeMap(({ payload }) => {
      const client = new ApiClient();
      const clientConfig = state$.value.clientConfig.clientConfig;
      const loading = of(actionCreators.stopAutoRenewLoading(true));

      const promises = payload.map(stopAutoRenewal => {
        let putBody: StopAutoRenewBody ;
        putBody = {
          prodIdAlias: stopAutoRenewal.prodIdAlias,
          accountNumber: stopAutoRenewal.accountNumber,
          removeFromCurrentSingleAutoRenewal: stopAutoRenewal.stopSingleAutoRenew,
          allowGiftAutoRenewal: stopAutoRenewal.stopGiftAutoRenew,
        };
        if (stopAutoRenewal.systemCode === 'CORE') {
          putBody.entityId = stopAutoRenewal.entityId;
          putBody.entityType = stopAutoRenewal.entityType;
          putBody.coreTransactionType = stopAutoRenewal.coreTransactionType;
          putBody.systemCode = stopAutoRenewal.systemCode;
        }

        const apiConfig: ApiConfig = {
          credentialsObject: state$.value.auth.omsAuth.credentials,
          providerType: clientConfig.providerType,
          url: clientConfig.url,
          path: STOP_AUTO_RENEW_PATH,
          region: clientConfig.Region,
          requestmethod: 'POST',
          requestbody: putBody,
          parms: {},
          additionalParms: {
            headers: {
              'x-session-id': clientConfig.uuid
            },
          },
        };
        return fromPromise(client.callApiGateway(apiConfig));
      });
      let loggedIncustomer = getLoggedInCustomer(state$.value);
      const refetchCustomerPayload = {
        accountNumber: loggedIncustomer.accountNumber,
        postalCode: loggedIncustomer.postalCode,
      };
      let reloadCust = of(weakActionCreators.refetchCustomer(refetchCustomerPayload));
      const source$ = forkJoin(promises, getStopAutoRenewSucceeded).pipe(
        map(responses => {
          let payloadCount = payload.length;
          if (payloadCount === responses.length) {
            gaActionCreators.createGAEvent({
              category: payload[0].googleAnalyticsCategory,
              action: 'Successful',
              label: payload[0].prodIdAlias
            });
            return actionCreators.stopAutoRenewSuccess({payload, responses, success: true});
          } else {
            gaActionCreators.createGAEvent({
              category: payload[0].googleAnalyticsCategory,
              action: 'Unsuccessful',
              label: payload[0].prodIdAlias
            });
            return actionCreators.stopAutoRenewError({
              success: false,
              message: 'Error on Stop Auto Renew',
            });
          }
        }),
        catchError(err => {
          gaActionCreators.createGAEvent({
            category:  payload[0].googleAnalyticsCategory,
            action: 'Unknown Error',
            label: payload[0].prodIdAlias
          });
          return of(
            actionCreators.stopAutoRenewError({ success: false, message: err }),
          );
        }),
      );
      return concat(loading, source$, reloadCust);
    }),
  );
export const epics = combineEpics(stopAutoRenew);
