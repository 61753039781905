import {
  ActionsObservable,
  StateObservable,
  ofType,
  combineEpics,
} from 'redux-observable';
import { RootAction, RootState } from '@src/app/_redux';
import { EditPermissionsPayload } from '@src/_api/payload/edit-permissions';
import { ApiClient } from '@src/app/_global/utility/apiUtils';
import { ApiConfig } from '@src/app/_global/domain';
import { actionCreators as gaActionCreators } from '@src/app/analytics/googleAnalytics/actions';
import {
  SAVE_PREFERENCES_START,
  actionCreators,
} from '@src/app/accounts/preferences';

import { map, mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { concat } from 'rxjs/observable/concat';

const translatePermissions = (oldPayload: any) => {
  let newPayload: any = {};
  let newPermissions = oldPayload.newPermissions;
  let currentPermissions = oldPayload.currentPermissions;
  for (let key in newPermissions) {
    if (key) {
      let newValue = newPermissions[key];
      let currentValue = currentPermissions[key];
      if (key === 'emailUseAnywherePerm' || key === 'emailUseForCompanyPerm') {
        // new and current value of these two permissions MUST be different
        // or SERV errors
        if ((!newValue && currentValue === 'N') || (newValue && currentValue === 'Y')) {
          continue;
        }
      }
      if (key !== 'accountNumber' && key !== 'prodIdAlias' && key !== 'email') {
        newPayload[key] = newValue ? 'Y' : 'N';
      } else {
        newPayload[key] = newValue;
      }
    }
  }
  return newPayload;
};

const saveAccountPermissions = (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<RootState>,
) =>
  action$.pipe(
    ofType(SAVE_PREFERENCES_START),
    mergeMap(({ payload }) => {
      const clientConfig = state$.value.clientConfig.clientConfig;
      const loading = of(actionCreators.savePreferencesLoading(true));
      const client = new ApiClient();

      let postBody: EditPermissionsPayload = translatePermissions(payload);
      const apiConfig: ApiConfig = {
        credentialsObject: state$.value.auth.omsAuth.credentials,
        providerType: clientConfig.providerType,
        url: clientConfig.url,
        path: '/cofp',
        region: clientConfig.Region,
        requestmethod: 'POST',
        requestbody: postBody,
        parms: {},
        additionalParms: {
          headers: {
            'x-session-id': clientConfig.uuid
          },
        },
      };

      const source$ = fromPromise(client.callApiGateway(apiConfig)).pipe(
        map(answer => {
          const isSuccess = String(answer.success) === 'true';
          if (isSuccess) {
            gaActionCreators.createGAEvent({
              category: payload.googleAnalyticsCategory,
              action: 'Successful',
              label: payload.selectedProdIdAlias
            });
            return actionCreators.savePreferencesSuccess({
              success: true,
              response: payload.newPermissions,
            });
          } else {
            gaActionCreators.createGAEvent({
              category: payload.googleAnalyticsCategory,
              action: 'Unsuccessful',
              label: payload.selectedProdIdAlias
            });
            return actionCreators.savePreferencesError({
              success: false,
              message: 'Error on Change Permissions',
            });
          }
        }),
        catchError(err => {
          gaActionCreators.createGAEvent({
            category: payload.googleAnalyticsCategory,
            action: 'Unknown Error',
            label: payload.selectedProdIdAlias
          });
          return of(
            actionCreators.savePreferencesError({
              success: false,
              message: err,
            }),
          );
        }),
      );

      return concat(loading, source$);
    }),
  );

export const epics = combineEpics(saveAccountPermissions);
