import { combineEpics } from 'redux-observable';
import { epics as SummaryEpics } from '@src/app/accounts/summary/epics';
import { epics as PreferencesEpics } from '@src/app/accounts/preferences/epics';
import { epics as IssueEpics } from '@src/app/accounts/issues/epics';
import { epics as SuspendEpics } from '@src/app/accounts/suspend/epics';
import { epics as ResumeEpics } from '@src/app/accounts/resume/epics';
import { epics as DiscontinueEpics } from '@src/app/accounts/series-update/epics';
import { epics as StopChargeEpics } from '@src/app/accounts/stopcharge/epics';
import { epics as CustomerServiceEpics } from '@src/app/accounts/customer-service/epics';
import { epics as UpdatePaymentEpics } from '@src/app/accounts/updatepayment/epics';
import { epics as StopAutoRenewEpics } from '@src/app/accounts/stopautorenew/epics';
import { epics as StartAutoRenewEpics } from '@src/app/accounts/startautorenew/epics';
import { epics as OrdersEpics } from '@src/app/accounts/orders/epics';
import { epics as PaymentsEpics } from '@src/app/accounts/payments/epics';
import { epics as RenewalsEpics } from '@src/app/accounts/renewals/epics';
export const epics = combineEpics(
  SummaryEpics,
  PreferencesEpics,
  IssueEpics,
  SuspendEpics,
  ResumeEpics,
  CustomerServiceEpics,
  DiscontinueEpics,
  StopChargeEpics,
  UpdatePaymentEpics,
  StopAutoRenewEpics,
  StartAutoRenewEpics,
  OrdersEpics,
  PaymentsEpics,
  RenewalsEpics,
);
