import { combineReducers } from 'redux';
import {
  reducer as summary,
  State as SummaryState,
} from '@src/app/accounts/summary/reducer';
import {
  reducer as orderData,
  State as OrderState,
} from '@src/app/accounts/orders/reducer';
import {
  reducer as customerData,
  State as CustomerState,
} from '@src/app/accounts/customers/reducer';
import {
  reducer as associatedGiftAccountsMap,
  State as AssociatedGiftAccountsState,
} from '@src/app/accounts/gifts/reducer';
import {
  reducer as paymentMethodsData,
  State as PaymentMethodsState,
} from '@src/app/accounts/payment-methods/reducer';
import {
  reducer as issuesData,
  State as IssuesState,
} from '@src/app/accounts/issues/reducer';
import {
  reducer as suspendData,
  State as SuspendState,
} from '@src/app/accounts/suspend/reducer';
import {
  reducer as resumeData,
  State as ResumeState,
} from '@src/app/accounts/resume/reducer';
import {
  reducer as customerServiceEmailData,
  State as CustomerServiceEmailState,
} from '@src/app/accounts/customer-service/reducer';
import {
  reducer as seriesUpdateData,
  State as SeriesUpdateState,
} from '@src/app/accounts/series-update/reducer';
import {
  reducer as stopChargeData,
  State as StopChargeState,
} from '@src/app/accounts/stopcharge/reducer';
import {
  reducer as stopAutoRenewData,
  State as StopAutoRenewState,
} from '@src/app/accounts/stopautorenew/reducer';
import {
  reducer as updatePaymentData,
  State as UpdatePaymentState,
} from '@src/app/accounts/updatepayment/reducer';
import {
  reducer as paymentsData,
  State as PaymentsState,
} from '@src/app/accounts/payments/reducer';
import {
  reducer as startAutoRenewData,
  State as StartAutoRenewState,
} from '@src/app/accounts/startautorenew/reducer';
import {
  reducer as renewalsData,
  State as RenewalsState,
} from '@src/app/accounts/renewals/reducer';
import {
  reducer as preferenceData,
  State as PreferenceState,
} from '@src/app/accounts/preferences/reducer';
interface StoreEnhancerState {}

export interface State extends StoreEnhancerState {
  summary: SummaryState;
  orderData: OrderState;
  customerData: CustomerState;
  associatedGiftAccountsMap: AssociatedGiftAccountsState;
  paymentMethodsData: PaymentMethodsState;
  issuesData: IssuesState;
  suspendData: SuspendState;
  resumeData: ResumeState;
  customerServiceEmailData: CustomerServiceEmailState;
  seriesUpdateData: SeriesUpdateState;
  stopChargeData: StopChargeState;
  stopAutoRenewData: StopAutoRenewState;
  updatePaymentData: UpdatePaymentState;
  paymentsData: PaymentsState;
  startAutoRenewData: StartAutoRenewState;
  preferenceData: PreferenceState;
  renewalsData: RenewalsState;
}

export const reducer = combineReducers<State, any>({
  summary,
  orderData,
  customerData,
  associatedGiftAccountsMap,
  paymentMethodsData,
  issuesData, 
  suspendData,
  resumeData,
  customerServiceEmailData,
  seriesUpdateData,
  stopChargeData,
  stopAutoRenewData,
  updatePaymentData,
  paymentsData,
  startAutoRenewData,
  preferenceData,
  renewalsData,
});
