const sha = require('sha.js');

/**
 * Perform a Ajax http request.
 *
 * @param  {string}  method  HTTP request like `POST` or `GET`.
 * @param  {string}  url  The request URL.
 * @param  {Object}  data Object contains request body.
 *
 * @return {Promise}      Promise resolved with response data.
 */
const request = (method, url, data) => {
  const http = new XMLHttpRequest();
  return new Promise((resolve, reject) => {
    http.open(method, url, true);

    if (method === 'POST') {
      http.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      http.send(data);
    } else {
      http.send();
    }

    http.onreadystatechange = () => {
      if (http.readyState !== 4) {
        return;
      }

      if (http.status >= 200 && http.status < 300) {
        resolve(http.responseText);
      } else {
        reject({
          status: http.status,
          statusText: http.statusText,
          error: http.responseText,
        });
      }
    };
  });
};

/**
 * Calls request with `POST`
 *
 * @param  {string}  url  The request URL.
 * @param  {Object}  data Object contains request body.
 *
 * @return {Promise}      Promise resolved with response data from request method.
 */
const post = async (url, data) => {
  const response = await request('POST', url, data);
  return JSON.parse(response);
};

/**
 * Calls request with `GET`
 *
 * @param {string} url The request URL.
 *
 * @return {Promise}      Promise resolved with response data.
 */
const get = async url => {
  const response = await request('GET', url);
  return response;
};

/**
 * Get URL parameter value.
 *
 * @param  {string} name                       Name of URL parameter to get its value.
 * @param  {string} [url=window.location.href] The URL string to get parameter value from it.
 *
 * @return {string}                            URL parameter value.
 */
const getUrlParameter = (name, url = window.location.href) => {
  const regex = new RegExp(`[?&]${encodeURIComponent(name)}=([^&#]*)`);
  const results = regex.exec(url);
  return results ? decodeURIComponent(results[1]) : null;
};

/**
 * Length of PKCE code verifier.
 */
const codeVerifierLength = 128;

/**
 * Convert string to Base64 string.
 *
 * @param  {string} data The string to convert.
 *
 * @return {string}      Base64 string.
 */
const base64URLEncode = data => {
  return window
    .btoa(data)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
};

/**
 * Add code verifier string to local storage.
 *
 * @param {string} key          The name of the key that used to store
 *                              code verifier value in local storage.
 * @param {string} codeVerifier Code verifier value to store.
 */
const setVerifierInStorage = (key, codeVerifier) => {
  localStorage.setItem(key, codeVerifier);
};

/**
 * Get code verifier value from local storage and clear local storage.
 *
 * @param  {string} key The name of the key that used to get
 *                      code verifier value from local storage.
 *
 * @return {string}     The retrieved code verifier.
 */
const getVerifierFromStorage = key => {
  const verifier = localStorage.getItem(key);
  localStorage.removeItem(key);
  return verifier;
};

/**
 * Create code verifier string.
 *
 * @return {string} The created verifier string.
 */
const generateVerifierString = () => {
  const buffer = new Uint8Array(codeVerifierLength);
  window.crypto.getRandomValues(buffer);
  const verifier = base64URLEncode(String.fromCharCode.apply(null, buffer));
  return verifier.substring(0, codeVerifierLength - 1);
};

/**
 * Generate a PKCE challenge from the code verifier stored in local storage.
 *
 * @param  {string} [localStorageKey='code-verifier'] The name of the key that used to store
 *                                                    code verifier value in local storage.
 *
 * @return {string}                                   The base64 URL encoded code challenge.
 */
const generateChallenge = (localStorageKey = 'code-verifier') => {
  const codeVerifier = generateVerifierString();
  setVerifierInStorage(localStorageKey, codeVerifier);

  return base64URLEncode(
    sha('sha256')
      .update(codeVerifier)
      .digest('latin1')
  );
};


/**
 * Generate the token scope values
 *
 * @param  {Array} [tokenScopes = []] The array of tokens scopes defined for the provider.
 *
 * @return {string} The token scopes formatted for the URL.
 */
const generateTokenScopeParameter = (tokenScopes = []) => {
  let tokenScopesString = '';

  tokenScopes.forEach(scope => {
    if (tokenScopesString !== '') {
      tokenScopesString += ' ';
    }
    tokenScopesString += scope; 
  });

  return tokenScopesString
};

module.exports = {
  getVerifierFromStorage,
  generateChallenge,
  generateTokenScopeParameter,
  post: post,
  get: get,
  getUrlParameter,
};
