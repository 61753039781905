import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
export interface LocalProps {
  userName: string;
  onClick: any;
  authLogin: boolean;
  providerType: string;
  myloUrl: any;
  className: string;
}
export class UserDropDown extends React.Component<LocalProps> {
  render() {
    return (
      <UncontrolledDropdown>
        <DropdownToggle caret={true} className={this.props.className}>
          <span className="user-name"> {this.props.userName}</span>{' '}
          <span
            className="fa fa-user-o fa-2x spacer-left user-icon"
            aria-label="User drop-down list"
          />
        </DropdownToggle>
        <DropdownMenu right={true}>
          <DropdownItem>
            {' '}
            <Link to="/accounts/summary">
              <FormattedMessage
                id={'acnt.myaccount'}
                description={'my account'}
                defaultMessage={'Account Summary'}
              />
            </Link>{' '}
          </DropdownItem>
          {this.props.authLogin && this.props.providerType !== 'CoreSSO' && ( 
            <DropdownItem divider={true} />
          )}
          {this.props.authLogin && this.props.providerType !== 'CoreSSO' && ( 
            <DropdownItem tag="a" target="_blank" href={this.props.myloUrl}>
              <FormattedMessage
                  id={'acnt.myloaccount'}
                  description={'mylo account'}
                  defaultMessage={'Manage My Sign In Info'}
                />
            </DropdownItem>
          )}
            <DropdownItem divider={true} />
          <DropdownItem
            className="user-drop-btn logout-btn"
            onClick={() => {
              this.props.onClick();
            }}
          >
            <FormattedMessage
              id={'nav.logout'}
              description={'logout'}
              defaultMessage={'Sign Out'}
            />
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}
