import * as React from 'react';
import { CognitoAuth } from 'amazon-cognito-auth-js';
import { connect } from 'react-redux';
import { RootState } from '@src/app/_redux';
import { Dispatch } from '@src/app/_redux';
import {
  actionCreators as cognitoActions,
  Actions,
  COGNITO_GET_IDENTITY_ID,
} from '@src/app/login/cognito/actions';
import { actionCreators as authActions } from '@src/app/header/actions';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import Mylo from 'mylo-js';
const coreSSOHelper = require('../coreSSO-helper');

export interface CognitoLoginProps {
  authData: CognitoAuthData;
  signInData: any;
  onSuccess: (payload: any) => Actions[typeof COGNITO_GET_IDENTITY_ID];
  mylo: Mylo;
  providerType: string;
}

export interface CognitoAuthData {
  ClientId: string;
  AppWebDomain: string;
  TokenScopesArray: string[];
  RedirectUriSignIn: string;
  RedirectUriSignOut: string;
  UserPoolId: string;
  AdvancedSecurityDataCollectionFlag: boolean;
}

export interface CognitoUserHandler {
  onSuccess: (payload: any) => Actions[typeof COGNITO_GET_IDENTITY_ID];
  onFailure: (error: any) => void;
}

export const Cognito: React.SFC<CognitoLoginProps> = props => {
  const onFailure = (err: any) => {
    // TODO implementation
    console.log(err);
  };
  const { authData, signInData, mylo, providerType } = props;
  let tokenScopes = '';
  let signUpURL = '';
  let signUpRedirectURL = '';
  let challenge = '';
  if (providerType === 'CoreSSO') {
    challenge = coreSSOHelper.generateChallenge('core-code-verifier');
    signUpURL = authData.AppWebDomain.replace('b2c_1_entitlements-api' , 'b2c_1_entitlements-api-signup');
    signUpRedirectURL = authData.RedirectUriSignIn.replace('core-sso' , 'core-sso-signup');
    tokenScopes = coreSSOHelper.generateTokenScopeParameter(authData.TokenScopesArray);
  }

  return (
    <>
      {signInData.EmailPasswordEnabled && providerType !== 'CoreSSO' && (
      <div className="email-password-enabled">
        <div className="sign-up">
          <button className="btn btn-primary" onClick={() => mylo.viewPopup('signup', { urlParams: { display: 'SSP' } })}>
            <FormattedMessage
              id="app.signup"
              description="Sign up button"
              defaultMessage={'Create an Account'}
            />
          </button>
        </div>
        <div className="sign-in">
          <button className="btn btn-primary" onClick={() => mylo.viewPopup('login', { urlParams: { display: 'SSP' } })}>
            <FormattedMessage
              id="app.securelogin"
              description="Secure Login button"
              defaultMessage={'Sign In'}
            />
          </button>
        </div>
      </div>
      )}
      {signInData.EmailPasswordEnabled && providerType === 'CoreSSO' && (
      <div className="email-password-enabled">
        <div className="sign-up">
          <button className="btn btn-primary" onClick={() => window.location.href = 
          `${signUpURL}/authorize?response_type=code&client_id=${authData.ClientId}&scope=${tokenScopes}&redirect_uri=${signUpRedirectURL}&code_challenge=${challenge}&code_challenge_method=S256`}>
            <FormattedMessage
              id="app.signup"
              description="Sign up button"
              defaultMessage={'Create an Account'}
            />
          </button>
        </div>
        <div className="sign-in">
          <button className="btn btn-primary" onClick={() => window.location.href = 
            `${authData.AppWebDomain}/authorize?response_type=code&client_id=${authData.ClientId}&scope=${tokenScopes}&redirect_uri=${authData.RedirectUriSignIn}&code_challenge=${challenge}&code_challenge_method=S256`}>
            <FormattedMessage
              id="app.securelogin"
              description="Secure Login button"
              defaultMessage={'Sign In'} 
            />
          </button>
        </div>
      </div>
      )}
    </>
  );
};

export const MyloLogout: React.SFC<any> = props => {
  window.opener.location.href = `/logout`; 
  window.close();
  return <div></div>;
};

export const CognitoLogout: React.SFC<any> = props => {
  props.logout();
  return <Redirect to="/" />;
};

const CognitoSuccess: React.SFC<CognitoLoginProps> = props => {
  const { authData, onSuccess } = props;
  const cognitoAuth = new CognitoAuth(authData);
  const onFailure = (err: any) => {
    console.log(err);
  };
  cognitoAuth.userhandler = { onSuccess: onSuccess, onFailure: onFailure };
  cognitoAuth.parseCognitoWebResponse(window.location.href);
  return <Redirect to="/accounts/summary" />;
};

const mapState = (state: RootState) => ({
  authData: state.clientConfig.clientConfig.ciamAuthData,
});

const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      onSuccess: cognitoActions.cognitoGetIdentityId,
    },
    dispatch,
  );

const mapLogoutState = (state: RootState) => ({
  state,
});

const mapLogoutDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      logout: authActions.userLogout,
    },
    dispatch,
  );

const mapMyloLogoutState = (state: RootState) => ({
   state,
});
  
const mapMyloLogoutDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      logout: authActions.userLogout,
    },
    dispatch,
  );
  
export const CognitoSuccessComponent = connect(
  mapState,
  mapDispatch,
)(CognitoSuccess);

export const CognitoLogoutComponent = connect(
  mapLogoutState,
  mapLogoutDispatch,
)(CognitoLogout);

export const MyloLogoutComponent = connect(
  mapMyloLogoutState,
  mapMyloLogoutDispatch,
)(MyloLogout);
