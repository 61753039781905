import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from '@src/app/_redux';
import { FormattedMessage } from 'react-intl';
import { CognitoAuthData } from './Cognito';
const coreSSOHelper = require('../coreSSO-helper');

export interface CoreSSOProps {
  authData: CognitoAuthData;
  currentView: string;
}

export class CoreSSOForm extends React.Component<CoreSSOProps> {
  constructor(props: any) {
    super(props);
  }

  async componentDidMount() {
    if (coreSSOHelper.getUrlParameter('code')) {
      let tokenURL = this.props.authData.AppWebDomain;
      if (this.props.currentView === 'core-sso-signup') {
        tokenURL = this.props.authData.AppWebDomain.replace('b2c_1_entitlements-api' , 'b2c_1_entitlements-api-signup');
      }
      let tokenScopes = coreSSOHelper.generateTokenScopeParameter(this.props.authData.TokenScopesArray);
      const body = {
        grant_type: 'authorization_code',
        code: coreSSOHelper.getUrlParameter('code'),
        code_verifier: coreSSOHelper.getVerifierFromStorage('core-code-verifier'),
        client_id: this.props.authData.ClientId,
        redirect_uri: this.props.authData.RedirectUriSignIn,
        scope: tokenScopes,
      };
      const formBody = Object.keys(body).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(body[key])).join('&');
      const data = await coreSSOHelper.post(`${tokenURL}/token`, formBody);
      window.location.href = `/auth/success/#access_token=${data.access_token}&id_token=${data.id_token}`; 
    } else {
      window.location.href = `/login`; 
    }
  }
  
  render() {
    return (
      <div className="core-view">

      <div>Click 
        <a href="/login"
          className=""
        >
          <FormattedMessage
            id="app.cancel"
            description="Cancel"
            defaultMessage=" here"
          />
        </a> to return to login.
      </div>
    </div>);
  }
}

const mapStateToProps = (state: RootState, props) => {
  return {
    authData: state.clientConfig.clientConfig.ciamAuthData,
    currentView: props.location.pathname.split('/')[2],
  };
};

export const CoreSSOSuccess = connect(mapStateToProps)(CoreSSOForm);
