import { combineEpics, Epic, ActionsObservable, StateObservable, ofType } from 'redux-observable';
import { RootAction, RootState } from '@src/app/_redux';
import { ApiClient } from '@src/app/_global/utility/apiUtils';
import { ApiConfig } from '@src/app/_global/domain';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { concat } from 'rxjs/observable/concat';
import { fromPromise } from 'rxjs/observable/fromPromise';
import { actionCreators, GET_ISSUES_START, SUSPEND_START } from '@src/app/accounts/suspend/actions';
import { actionCreators as weakActionCreators } from '@src/app/login/weak/actions';
import { actionCreators as gaActionCreators } from '@src/app/analytics/googleAnalytics/actions';
import { getLoggedInCustomer } from '@src/app/accounts/selectors';
import { ServSuspendPostbody, SuspendPayload } from '@src/_api/payload/suspend';
import { forkJoin } from 'rxjs/observable/forkJoin';
import { SeriesUpdatePutbody } from '@src/_api/payload/series';

const ISSUES_PATH = '/issue';
const SERV_SUSPEND_PATH = '/cofp';
const PF_SUSPEND_PATH = '/series';
const getSuspendsSucceeded = (...responses) => {
  const successResponses = responses.filter(item => 
    item.success === true);
  return successResponses;
};

const getIssuesSucceeded = (...responses) => {
  const successResponses = responses.filter(item => 
    JSON.parse(item).isSuccess === 'true');
  return successResponses;
};

const formatPostbody = (suspend: SuspendPayload) => {
    let postBody: ServSuspendPostbody | SeriesUpdatePutbody;
    if (suspend.systemCode === 'SERV') {
      if (!suspend.resume) {
        postBody = {
          prodIdAlias: suspend.prodIdAlias,
          accountNumber: suspend.accountNumber,
          suspend: suspend.suspend,
          reasonCode: suspend.reasonCode ? suspend.reasonCode : '',
          currentMagSwitch: 'X',
        };
      } else if (suspend.resume && suspend.resume === 'I') {
        postBody = {
          prodIdAlias: suspend.prodIdAlias,
          accountNumber: suspend.accountNumber,
          suspend: suspend.suspend,
          reasonCode: suspend.reasonCode ? suspend.reasonCode : '',
          currentMagSwitch: 'X',
        };
      } else { 
        postBody = {
          prodIdAlias: suspend.prodIdAlias,
          accountNumber: suspend.accountNumber,
          suspend: suspend.suspend,
          reasonCode: suspend.reasonCode ? suspend.reasonCode : '',
          resumeIssue: suspend.resume,
          currentMagSwitch: 'X',
        };
      }
    } else {  // PF Series suspend/resume
      postBody = {
        prodIdAlias: suspend.prodIdAlias,
        accountNumber: suspend.accountNumber,
        updateType: 'suspendresume',
        suspendSwitch: suspend.suspend,
        planCode: suspend.planCode ? suspend.planCode : '',
        recipAccountNumber: suspend.recipAccountNumber,
        resumeServiceDate: suspend.resume ? suspend.resume : '',
        shipFrequency: suspend.shipFrequency ? suspend.shipFrequency : '',
      };
    }

    return postBody;
};

const getIssues =  (
  action$: ActionsObservable<RootAction>,
  state$: StateObservable<RootState>,
) =>
    action$.pipe(
      ofType(GET_ISSUES_START),
      mergeMap(({ payload }) => {
      const client = new ApiClient();
      const clientConfig = state$.value.clientConfig.clientConfig;
      const loading = of(actionCreators.getIssuesLoading(true));

      const promises = payload.orders.map(order => {
        let orderAcctNumber = order.isGift ? order.orderItems[0].shipToAccountNumber : order.billToAccountNumber;
        let customer = payload.customers.find( cust => {
          return cust.accountNumber === orderAcctNumber
          && cust.prodIdAlias === order.prodIdAlias;
        });
     
        const apiConfig: ApiConfig = {
          credentialsObject: state$.value.auth.omsAuth.credentials,
          providerType: clientConfig.providerType,
          url: clientConfig.url,
          path: ISSUES_PATH,
          region: clientConfig.Region,
          requestmethod: 'GET',
          requestbody: {},
          parms: {},
          additionalParms: {
            headers: {
              'x-session-id': clientConfig.uuid
            },
            queryParams: {
              magCode: order.prodIdAlias,
              issueNumberStart: customer.lastIssueNumber === '' ? 1 : parseInt(customer.lastIssueNumber, 10) + 1,
              issueNumberEnd: customer.expireIssueNumber
            }
          },
        };
        return fromPromise(client.callApiGateway(apiConfig));
      });

      const source$ = forkJoin(promises, getIssuesSucceeded).pipe(
        map(responses => {
          let orderCount = payload.orders.length;
          if (orderCount === responses.length) {
            let jsonResponse = JSON.parse('[' + responses.join(',') + ']');
            return actionCreators.getIssuesSuccess(jsonResponse);
          } else {
            return actionCreators.getIssuesError({
              success: false,
              message: 'Error on Issue Lookup',
            });
          }
        }),
        catchError(err => {
          console.log(err);
          return of(
            actionCreators.getIssuesError({ success: false, message: err }),
          );
        }),
      );
      return concat(loading, source$);
    }),
  );

  const suspendSubs =  (
    action$: ActionsObservable<RootAction>,
    state$: StateObservable<RootState>,
  ) =>
      action$.pipe(
        ofType(SUSPEND_START),
        mergeMap(({ payload }) => {
        const client = new ApiClient();
        const clientConfig = state$.value.clientConfig.clientConfig;
        const loading = of(actionCreators.suspendLoading(true));

        const promises = payload.map(suspend => {
          let path = '';
          let requestMethod = '';
          if (suspend.systemCode === 'SERV') {  // suspend Serv Subscription
            path = SERV_SUSPEND_PATH;
            requestMethod = 'POST';
          } else {  // call series epic to suspend PF series
            path = PF_SUSPEND_PATH;
            requestMethod = 'PUT';
          }
            const apiConfig: ApiConfig = {
              credentialsObject: state$.value.auth.omsAuth.credentials,
              providerType: clientConfig.providerType,
              url: clientConfig.url,
              path: path,
              region: clientConfig.Region,
              requestmethod: requestMethod,
              requestbody: formatPostbody(suspend),
              parms: {},
              additionalParms: {
                headers: {
                  'x-session-id': clientConfig.uuid
                },
              },
            };
            return fromPromise(client.callApiGateway(apiConfig));
        });
        let loggedIncustomer = getLoggedInCustomer(state$.value);
        const refetchCustomerPayload = {
          accountNumber: loggedIncustomer.accountNumber,
          postalCode: loggedIncustomer.postalCode,
        };
        let reloadCust = of(weakActionCreators.refetchCustomer(refetchCustomerPayload));
        const source$ = forkJoin(promises, getSuspendsSucceeded).pipe(
          map(responses => {
            let suspendCount = payload.length;
            if (suspendCount === responses.length) {
              payload.forEach(suspendPayload => {
                gaActionCreators.createGAEvent({
                  category: suspendPayload.googleAnalyticsCategory,
                  action: 'Successful',
                  label: suspendPayload.prodIdAlias,
                });
              });
              return actionCreators.suspendSuccess({payload, responses, success: true});
            } else {
              payload.forEach(suspendPayload => {
                gaActionCreators.createGAEvent({
                  category: suspendPayload.googleAnalyticsCategory,
                  action: 'Unsuccessful',
                  label: suspendPayload.prodIdAlias,
                });
              });
              return actionCreators.suspendError({
                success: false,
                message: 'Error on Suspend',
              });
            }
          }),
          catchError(err => {
            gaActionCreators.createGAEvent({
              category: payload[0].googleAnalyticsCategory,
              action: 'Unknown Error',
              label: payload[0].prodIdAlias,
            });
            return of(
              actionCreators.suspendError({ success: false, message: err }),
            );
          }),
        );
        return concat(loading, source$, reloadCust);
      }),
    );
export const epics = combineEpics(getIssues, suspendSubs);
